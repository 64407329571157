import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'

import Hero from '../../../components/dai/Hero'
import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import Quote from '../../../components/dai/Quote'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'
import { Button } from '../../../components/dai/Button'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'
import TextLink from '../../../components/common/TextLink'

import Touchpad from '../../../images/dai/icons/assets/touchpad.svg'
import PhoneMessage from '../../../images/dai/icons/assets/phone-message.svg'
import Unicast from '../../../images/dai/icons/assets/unicast.svg'
import BrokenComputer from '../../../images/dai/icons/assets/broken-computer.svg'

const MaxImage = styled.div`
  max-width: 200px;
`

export default function Insurance() {
  return (
    <LayoutDAI
      freeAudit
      showIndustryReports
      pagePath={{
        parent: 'industry-reports',
        child: 'insurance',
        freeAuditHeading: 'Find out how your site compares to other insurance sites.',
      }}
    >
      <SEO
        title="Insurance Accessibility | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/industry-reports/insurance/"
        desc="Our expert review of top insurance sites found another 7 accessibility barriers across the pages tested, which included policy pages, FAQs, and agent profiles."
        bannerUrl="https://images.prismic.io/audioeye-web/7259f3a9-a2c7-4eb5-9573-deef118d4c6c_insurance.jpg?auto=compress,format"
        imageAlt="Stylized billboard for the Insurance industry with error message that reads “Form Field Error”"
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/industry-reports/insurance/`}
      />
      <Hero
        image={
          <StaticImage
            src="../../../images/dai/hero/insurance.png"
            alt="Stylized billboard for the Insurance industry with error message that reads “Form Field Error”"
          />
        }
        backgroundColor="orange200"
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          INSURANCE
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Insurance">
          Accessibility barriers can make getting coverage a total loss.
        </Heading1>
        <Space height={32} />
        <Text>
          For people with disabilities, keyboard and form accessibility barriers can make it difficult to compare plans,
          request a quote, or even look up coverage rates for their address. Often, the path of least resistance is to
          reach out to customer support — although several of our community members noted that pushing them into a phone
          queue should not be mistaken as an accessibility strategy.
        </Text>
      </Hero>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“Every kind of insurance I’ve ever had, I have to reach out by phone or email because the process of signing up or updating my personal information is wildly inaccessible.”"
          author="Lia Stone"
          title="AudioEye A11iance Member & QA Tester"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          EXPERT AUDITS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Breaking down the most significant barriers on the top insurance sites.
        </Heading2>
        <Space height={32} />
        <Text>
          After reviewing the top insurance sites, our expert testers noted that keyboard accessibility issues could
          prevent screen reader users from taking key actions on the site, such as looking up coverage maps, searching
          for agents, or logging into their account.
        </Text>
        <Space height={32} />
        <Text>
          On average, our testers found another{' '}
          <BoldText>
            7 accessibility barriers across the pages tested, which included policy pages, FAQs, and agent profiles
          </BoldText>{' '}
          — including four consistent barriers that impacted their ability to create or manage accounts.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns orderedList>
          <Column size={12} listItem>
            <IconCard noCard>
              <Touchpad />
              <Space height={16} />
              <Text heading3 className="asH4">
                1. Screen reader users were unable to interact with coverage maps.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted that interactive maps on several pages had limited accessibility, with screen reader
                users unable to zoom in or out, gain basic street information, or understand what the map was
                highlighting.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                  target="_blank"
                  rel="noreferrer"
                  text="1.1.1: Non-Text Content"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <PhoneMessage />
              <Space height={16} />
              <Text heading3 className="asH4">
                2. Screen reader users were unable to get in touch with agents.
              </Text>
              <Space height={16} />
              <Text>
                Our experts encountered multiple “Find an Agent” buttons that directed them to a new page without any
                agent information. This made it difficult to connect with prospective agents or get a quote.
              </Text>
              <Space height={32} />
              <Text>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
                  target="_blank"
                  rel="noreferrer"
                  text="4.1.2: Name, Role, Value"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Mobility
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <Unicast />
              <Space height={16} />
              <Text heading3 className="asH4">
                3. Vague link descriptions made site navigation a challenge.
              </Text>
              <Space height={16} />
              <Text>
                Our experts encountered multiple links that were simply labeled “Go,” which made site navigation a
                difficult task and forced them to spend time clicking on links to see if they would be taken to the
                right page.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
                  target="_blank"
                  rel="noreferrer"
                  text="4.1.2: Name, Role, Value"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <BrokenComputer />
              <Space height={16} />
              <Text heading3 className="asH4">
                4. Broken buttons stopped keyboard-only users in their tracks.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted multiple instances where they were unable to log into their account using a keyboard
                alone. Clicking the log-in button did not trigger any kind of announcement or focus change for screen
                reader users.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
                  target="_blank"
                  rel="noreferrer"
                  text="4.1.2: Name, Role, Value"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“It can be really stressful having to call support. You never know what kind of service you’re going to get. Sometimes they’re super helpful, and other times you have to hang up and hope for someone better.”"
          author="Marche Roberson"
          title="AudioEye A11iance Member"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          AUTOMATED SCAN RESULTS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Most common issues, insurance sites.
        </Heading2>
        <Space height={32} />
        <Text>
          Although insurance sites performed better than average for most of the tests conducted by our automated scan,
          they still had a high rate of accessibility issues that affected people’s ability to request a quote, enter
          personal information, or navigate to specific pages.
        </Text>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/stop-sign-73.png"
                alt="Pie chart representing 73%. The pie chart is in the shape of a stop sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              73% of pages on insurance sites had at least one image with missing or inadequate alt text.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive alt text on images and other graphics, people with visual and cognitive impairments
              can struggle to understand what each one is supposed to illustrate.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                target="_blank"
                rel="noreferrer"
                text="1.1.1: Non-Text Content"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/stop-sign-39.png"
                alt="Pie chart representing 39%. The pie chart is in the shape of a stop sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              39% of insurance pages with a form had at least one field that was not labeled.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive field labels, it can be difficult for people with visual and cognitive impairments to
              request a quote or update their account information.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                target="_blank"
                rel="noreferrer"
                text="3.3.2: Labels or Instructions"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" paddingBottom="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/stop-sign-37.png"
                alt="Pie chart representing 37%. The pie chart is in the shape of a stop sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              37% of pages on insurance sites were missing frame titles.
            </Text>
            <Space height={24} />
            <Text>
              Missing frame titles can make it difficult for screen reader users to find content or orient themselves
              within a page.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/page-titled.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.2: Page Titles"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="xl" paddingBottom="xl" constraint="lg" backgroundColor="orange300">
        <Columns>
          <Column size={16} tabletSize={14}>
            <Text heading3 className="asH4">
              Get expert insight into the accessibility of your top pages and customer portals.
            </Text>
            <Space height={32} />
            <Text>
              Uncover accessibility barriers that are making it hard for people with disabilities to sign up or manage
              their account with an expert audit of your site.
            </Text>
          </Column>
          <Column size={8} tabletSize={10} justifyCenter alignItemsEnd>
            <Button
              text="Schedule Expert Audit"
              to="/digital-accessibility-index/industry-reports/insurance/#expert-audit"
              size="small"
              tag="DAI Page Content"
            />
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/industry-reports/media/"
        previousPageText="Media"
        nextPageLink="/digital-accessibility-index/industry-reports/government/"
        nextPageText="Government"
      />
    </LayoutDAI>
  )
}
